/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

button {
  transition: background-color 0.3s ease, color 0.3s ease;
}

button.bg-blue-500 {
  background-color: #4299e1;
}

button.bg-blue-500:hover {
  background-color: #3182ce;
}

button.bg-gray-300 {
  background-color: #e2e8f0;
}

button.bg-gray-300:hover {
  background-color: #cbd5e0;
}

button.bg-green-500 {
  background-color: #48bb78;
}

button.bg-green-500:hover {
  background-color: #38a169;
}

button.bg-red-500 {
  background-color: #f56565;
}

button.bg-red-500:hover {
  background-color: #e53e3e;
}

.bg-green-200 {
  background-color: #c6f6d5;
}

.bg-red-200 {
  background-color: #fed7d7;
}
